<footer class="footer-one footer-one--footer-three footer-one--footer-three--about">
  <div class="footer-two__bg style2"></div>
  <div class="container">
    <div class="footer-one__top">
      <div class="row">
        <div class="col-xl-9 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.3s">
          <div class="footer-widget__column footer-widget__company mar-top">
            <h3 class="footer-widget__title">LevizAlbania</h3>
            <p>
              LevizAlbania është një projekt i Agjencisë Zvicerane për Zhvillim dhe Bashkëpunim SDC që zbatohet nga
              konsorciumi i tre organziatave shqiptare “Fondacioni Shoqëria e Hapur për Shqipërinë“, Partneret Shqipëri
              për Ndryshim dhe Zhvillim dhe Co-PLAN, Instituti i Zhvillimit të Habitatit.

              <br>
              <br>
              Përdorimi i dokumentave dhe produkteve që gjenden në këtë Platformë mund të përdoren lirisht nga kushdo,
              me kushtin e vetëm që të citohen autorët dhe organizata që i ka krijuar, si dhe Projekti LevizAlbania dhe
              Agjencia Zviceriane për Zhvillim dhe Bashkëpunim (SDC)

            </p>
          </div>
        </div>
        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 wow animated fadeInUp" data-wow-delay="0.7s">
          <div class="footer-widget__column footer-widget__instagram mar-top">
            <h3 class="footer-widget__title">Social Media</h3>


            <ul class="instagram list-unstyled">

              <li>
                <a href="https://www.facebook.com/www.levizalbania.al"><img src="assets/images/icon/FACEBOOK.png"
                                                                            alt="ig"></a>
              </li>
              <li>
                <a href="https://www.instagram.com/levizalbania/"><img src="assets/images/icon/INSTA.png" alt="ig"></a>
              </li>
              <li>

                <a href="https://www.youtube.com/channel/UCEQpv-TZKk65Iz9G6gvzDnA"> <img src="assets/images/icon/YOUTUBE.png"
                                                                             alt="ig"></a>

              </li>
              <li>
                <a href="https://twitter.com/LevizAlbania"> <img src="assets/images/icon/TWITTER.png"
                                                                             alt="ig"></a>
              </li>
              <!-- Data -->
            </ul>
          </div>
        </div>
        <!--End Footer Widget Column-->
      </div>
    </div>
    <!--Start Footer One Bottom-->
    <div class="footer-one__bottom">
      <div class="row">
        <div class="col-xl-12">
          <div class="footer-one__bottom-inner">
            <div class="footer-one__bottom-text text-center">
              <p>&copy;2022 All Rights Reserved. Design And Developed By <a href="http://cube.al/">cube.al</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--End Footer One Bottom-->
  </div>
</footer>

