import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';

@Injectable()
export class LoginService {


  constructor(private http: HttpClient) {
  }


  public login(username: string, password: string): Observable<HttpResponse<any>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'X-Tenant-ID': 'tenant1',
      'Access-Control-Allow-Origin': '*'
    });
    const url = `${environment.baseUrl}/api/auth/login`;

    return this.http.post(url, JSON.stringify({username, password}), {observe: 'response', headers});
  }


  public resetPass(oldPass: string, newPass: string, userId: number): Observable<HttpResponse<any>> {

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      // @ts-ignore
      Authorization: 'Bearer ' + localStorage.getItem('jwt').toString()
    });
    const url = `${environment.baseUrl}/api/users/${userId}/reset-password`;

    return this.http.post(url, JSON.stringify({oldPassword: oldPass, newPassword: newPass}), {observe: 'response', headers});
  }


}
