import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProductDetailsComponent} from './components/pages/product-details/product-details.component';

const routes: Routes = [


  {
    path: '', loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule),
    data: {breadcrumb: 'Faqja kryesore'}
  },

  {
    path: 'about',
    loadChildren: () => import('./components/pages/about/about.module').then(m => m.AboutModule),
    data: {breadcrumb: 'KMCE'}
  },


  {
    path: 'product',
    loadChildren: () => import('./components/pages/product-main/product.module').then(m => m.ProductModule),
    data: {breadcrumb: 'Produktet'}
  },
  {
    path: 'product-details/:id',
    component: ProductDetailsComponent,
    data: {breadcrumb: 'Produktet'}
  },
  {
    path: 'product/:param',
    loadChildren: () => import('./components/pages/product-main/product.module').then(m => m.ProductModule),
    data: {breadcrumb: 'Produktet'}
  },
  {
    path: 'product/:key/:value',
    loadChildren: () => import('./components/pages/product-main/product.module').then(m => m.ProductModule),
    data: {breadcrumb: 'Produktet'}
  },
  {
    path: 'login',
    loadChildren: () => import('./components/pages/login/login.module').then(m => m.LoginModule),
    data: {breadcrumb: 'Login'}
  },
  {
    path: 'platforma',
    loadChildren: () => import('./components/pages/project/project.module').then(m => m.ProjectModule),
    data: {breadcrumb: 'Platformat'}
  },
  {
    path: 'error-404',
    loadChildren: () => import('./components/pages/error-page/error-page.module').then(m => m.ErrorPageModule),
    data: {breadcrumb: 'Error 404'}
  },

  {
    path: 'create-product',
    loadChildren: () => import('./components/pages/create-product/create-product.module').then(m => m.CreateProductModule),
    data: {breadcrumb: 'Shto Produkt'}
  },
  {
    path: 'edit-product/:id',
    loadChildren: () => import('./components/pages/create-product/create-product.module').then(m => m.CreateProductModule),
    data: {breadcrumb: 'Edito Produkt'}
  },


  {
    path: '**',
    loadChildren: () => import('./components/pages/error-page/error-page.module').then(m => m.ErrorPageModule),
    data: {breadcrumb: 'Error 404'}
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
