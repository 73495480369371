import {Component, DoCheck, EventEmitter, OnInit, Output} from '@angular/core';
import {HelperService} from '../../helper/helper.service';
import data from '../../data/navigation.json';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent extends HelperService implements OnInit, DoCheck {
  public navigation = data;

  @Output() routeChangeEvent = new EventEmitter();

  constructor(private route: Router) {
    super();
  }


  public isAuthenticated = false;

  ngOnInit(): void {
  }

  ngDoCheck(): void {

    if (localStorage.getItem('jwt') != undefined) {

      let token = '';
      // @ts-ignore
      token = localStorage.getItem('jwt').toString();

      this.isAuthenticated = !this.tokenExpired(token);

    } else {
      this.isAuthenticated = false;
    }


  }


  private tokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }

  // tslint:disable-next-line:typedef
  logOut() {
    this.route.navigate(['/']);
    const language = localStorage.getItem('language');
    localStorage.clear();
    if (language != null) {
      localStorage.setItem('lang', language);
    }

  }

  // tslint:disable-next-line:typedef
  isActiveProductPage() {
    const test = this.route.isActive('/product', true);
    if (test) {
      this.resetProducts();
    }
  }

  // tslint:disable-next-line:typedef
  resetProducts() {
    this.routeChangeEvent.emit();
  }

}
