import {Injectable, HostListener, AfterViewInit, OnInit} from '@angular/core';
import $ from 'jquery';
import 'magnific-popup';

@Injectable({
  providedIn: 'root'
})
export class HelperService implements AfterViewInit, OnInit {

  constructor() {
  }
  windowScrolled: boolean | undefined;

  // navigation
  navmethod = false;

  // Mobile
  open = false;

  // Search
  searchMethod = false;

  // Sticky Nav
  @HostListener('window:scroll', [])
  // tslint:disable-next-line:typedef
  onWindowScroll() {
    if (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 200) {
      this.windowScrolled = true;
    } else if (this.windowScrolled && window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop < 10) {
      this.windowScrolled = false;
    }
  }

  // tslint:disable-next-line:typedef
  toggleNav() {
    this.navmethod = !this.navmethod;
    const element = document.getElementById('body') as HTMLElement;
    element.classList.toggle('locked');
  }

  trigger(item: { open: boolean }) {
    item.open = !item.open;
  }

  // tslint:disable-next-line:typedef
  toggleSearch() {
    this.searchMethod = !this.searchMethod;
    const element = document.getElementById('body') as HTMLElement;
    element.classList.toggle('locked');
  }

  // tslint:disable-next-line:contextual-lifecycle
  ngOnInit(): void {

  }

  // tslint:disable-next-line:contextual-lifecycle
  ngAfterViewInit(): void {
    ($('.video-popup') as any).magnificPopup({
      type: 'iframe'
    });
    ($('.img-popup') as any).magnificPopup({
      type: 'image',
      gallery: {
        enabled: false,
      },
      mainClass: 'mfp-fade',
    });
  }
}
