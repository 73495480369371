import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BreadcrumbModule} from 'angular-crumbs';
import {HttpClientModule} from '@angular/common/http';
import {NgCircleProgressModule} from 'ng-circle-progress';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {PreloaderComponent} from './preloader/preloader.component';
import {LoginService} from './services/login.service';
import {ProductService} from './services/product.service';
import { ProductDetailsComponent } from './components/pages/product-details/product-details.component';
import {NgxDocViewerModule} from 'ngx-doc-viewer';
import {PublicProductsService} from './services/public.products.service';
import {SharedModule} from './components/shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    ProductDetailsComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BreadcrumbModule,
    NgbModule,
    HttpClientModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100
    }),
    NgxDocViewerModule,
    SharedModule
  ],
  providers: [
    LoginService,
    ProductService,
    PublicProductsService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}

