import {Component, DoCheck, OnDestroy, OnInit} from '@angular/core';
import {Product, ProjectLocation} from '../../../interfaces/project.interface';
import {ActivatedRoute, Router} from '@angular/router';
import {PublicProductsService} from '../../../services/public.products.service';
import 'magnific-popup';
import {DomSanitizer} from '@angular/platform-browser';

declare var require: any;
const FileSaver = require('file-saver');

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements OnInit, OnDestroy, DoCheck {

  loading = false;

  hover = false;

  isAuthenticated = false;

  private id: number | undefined;
  private sub: any;

  public product: Product | null | undefined;

  // @ts-ignore
  public locations: undefined | ProjectLocation[];
  // @ts-ignore
  public topics: string | Topic[] | undefined;
  // @ts-ignore
  public mechanisms: string | InterventionMechanism[] | undefined;

  public authors: string | string[] | undefined;

  safeURL = null;

  constructor(private route: ActivatedRoute, private router: Router,
              protected sanitizer: DomSanitizer, private publicProductsService: PublicProductsService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.isAuthenticated = false;
    this.sub = this.route.params.subscribe(params => {
      this.id = +params.id; // (+) converts string 'id' to a number

      this.getProductDetails(this.id);
    });

  }

  ngDoCheck(): void {

    if (localStorage.getItem('jwt') != undefined) {

      let token = '';
      // @ts-ignore
      token = localStorage.getItem('jwt').toString();

      this.isAuthenticated = !this.tokenExpired(token);

    } else {
      this.isAuthenticated = false;
    }
  }

  // tslint:disable-next-line:typedef
  private tokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }


  // tslint:disable-next-line:typedef
  private getProductDetails(id: number) {


    this.publicProductsService.getOne(id).subscribe(data => {
      this.product = data.body;

      this.locations = !this.product ? undefined : this.product.project.projectLocations;

      this.topics = !this.product ? '' : this.product?.project.topics;

      this.mechanisms = !this.product ? '' : this.product?.interventionMechanisms;

      // @ts-ignore
      this.authors = !this.product ? '' : this.product?.productAuthors.toString().split(',');


      this.loading = false;
      this.changeVideoUrl();
    });

  }

  // tslint:disable-next-line:typedef
  ngOnDestroy() {
    this.sub.unsubscribe();
  }


  // tslint:disable-next-line:typedef
  public downloadPdf(pdfUrl: any, pdfName: any) {
    FileSaver.saveAs(pdfUrl, pdfName);
  }

  // tslint:disable-next-line:typedef
  public getVideoId(url: string) {
    // extract video id from youtube url
    const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    // tslint:disable-next-line:triple-equals
    if (match && match[2].length == 11) {
      return 'https://www.youtube.com/embed/' + match[2];
    } else {
      return null;
    }
  }

  // tslint:disable-next-line:typedef
  private changeVideoUrl() {
    if (this.product?.productAttachments) {
      this.product.productAttachments.forEach(it => {
        if (it.fileType === 'video') {
          // @ts-ignore
          this.safeURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.getVideoId(it.fullUrl));
        }
      });
    }
  }

  // tslint:disable-next-line:typedef
  showAllProducts() {
    this.router.navigate(['product']);
  }

  // tslint:disable-next-line:typedef
  updateProduct(id: number) {
    this.router.navigate(['edit-product', id]);
  }


  // tslint:disable-next-line:typedef
  mekanizmiSelected(id: number) {
    this.router.navigate(['product', {key: 'mekanizmi', value: id}]);
  }

  // tslint:disable-next-line:typedef
  tematikaSelected(id: number) {
    this.router.navigate(['product', {key: 'tematika', value: id}]);

  }

  // tslint:disable-next-line:typedef
  zonaSelected(id: any) {
    this.router.navigate(['product', {key: 'zona', value: id}]);
  }

  // tslint:disable-next-line:typedef
  searchSelected(name: string) {
    this.router.navigate(['product', {key: 'search', value: name}]);
  }

  // tslint:disable-next-line:typedef
  yearSelected(name: string) {
    this.router.navigate(['product', {key: 'year', value: name}]);
  }


}
