import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Product} from '../interfaces/project.interface';
import {environment} from '../../environments/environment';


@Injectable()
export class ProductService {


  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'http://localhost:4200'
  });

  constructor(private http: HttpClient) {
  }


  getAllProducts(body: any): Observable<HttpResponse<Product[]>> {
    const headers = this.headers;
    const url = `${environment.baseUrl}/api/public/products/_search`;
    return this.http.post<Product[]>(url, JSON.stringify(body), {observe: 'response', headers});
  }


  getAllMechanisms(): Observable<HttpResponse<any>> {
    const headers = this.headers;
    const url = `${environment.baseUrl}/api/public/projects/mechanisms`;

    // const body = { search: '', tags: [], topics: [], mechanisms: [], locations: [], pageNumber: 0, pageSize: 10 };

    return this.http.get(url, {observe: 'response', headers});
  }

  getAllProjectsSimple(): Observable<HttpResponse<any>> {
    const headers = this.headers;
    const url = `${environment.baseUrl}/api/public/projects/simple`;
    return this.http.get(url, {observe: 'response', headers});
  }


  getAllTopics(): Observable<HttpResponse<any>> {
    const headers = this.headers;
    const url = `${environment.baseUrl}/api/public/projects/topics`;

    // const body = { search: '', tags: [], topics: [], mechanisms: [], locations: [], pageNumber: 0, pageSize: 10 };

    return this.http.get(url, {observe: 'response', headers});
  }

  getAllLocations(): Observable<HttpResponse<any>> {
    const headers = this.headers;
    const url = `${environment.baseUrl}/api/public/projects/locations`;

    // const body = { search: '', tags: [], topics: [], mechanisms: [], locations: [], pageNumber: 0, pageSize: 10 };

    return this.http.get(url, {observe: 'response', headers});
  }


  // tslint:disable-next-line:typedef
  getAllCounterObj() {
    const headers = this.headers;
    const url = `${environment.baseUrl}/api/public/home/content-info`;

    return this.http.get(url, {observe: 'response', headers});
  }

  saveProduct(body: any, projectId: any): Observable<HttpResponse<any>> {


    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      // @ts-ignore
      Authorization: 'Bearer ' + localStorage.getItem('jwt').toString()
    });

    const url = `${environment.baseUrl}/api/projects/${projectId}/products`;
    return this.http.post<any>(url, JSON.stringify(body), {observe: 'response', headers});
  }


  saveFile(body: any): Observable<HttpResponse<any>> {


    const headers = new HttpHeaders({
      // 'Content-Type': 'multipart/form-data',
      accept: 'application/json',
      // @ts-ignore
      Authorization: 'Bearer ' + localStorage.getItem('jwt').toString()
    });

    const url = `${environment.baseUrl}/api/files`;
    return this.http.post<any>(url, body, {observe: 'response', headers});
  }


}
