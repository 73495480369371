<app-header></app-header>
<app-breadcrumbs></app-breadcrumbs>


<div class="row">
  <div style="padding-top: 1%;margin-right: 10%;margin-left: 5%;" class="col">

    <span (click)="showAllProducts()" class="thm-btn-all">Shiko të gjithë Produktet  &nbsp;&nbsp;<i
      class="fa fa-hand-pointer"></i></span>

  </div>
</div>

<section class="portfolio-details" *ngIf="product">
  <div style="margin-left: 5%; margin-right: 10%; width: 100%">

    <div style="width: 95%" class="row">
      <div class="col-lg-7">
        <div class="portfolio-details__content">
          <div class="row">
            <h3 class="portfolio-details__content__title col-md-10" style="margin-top: 0px; margin-bottom: 0px">{{product.title}}</h3>
            <span *ngIf="isAuthenticated" (click)="updateProduct(product.id)" class="thm-btn-all col-md-2">Edito Produktin</span></div>

          <p class='letter-caps'> {{product.description}}</p>
          <div class="portfolio-details__gallery">
            <div class="row">
              <div class="" *ngFor="let attachment of product.productAttachments">
                <ng-container *ngIf="attachment.fileType=='pdf' || attachment.fileType=='document'">
                  <button (click)="downloadPdf(attachment.fullUrl, attachment.fileName)" class="btn"><i
                    class="fa fa-download"></i> Shkarko Dokumentin
                  </button>
                </ng-container>
                <figure>
                  <span *ngIf="attachment.fileType=='video'">
                    <iframe style="width:100%;height:50vh;" [src]="safeURL" frameborder="0" gesture="media"
                            allow="encrypted-media" allowfullscreen=""></iframe>
                    <div class="cta-one__btn-box text-center"></div>
                  </span>
                  <span *ngIf="attachment.fileType=='pdf'||attachment.fileType=='document'"><ngx-doc-viewer
                    [url]="attachment.fullUrl"
                    viewer="google"
                    style="width:100%;height:100vh;"
                  ></ngx-doc-viewer></span>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5">
        <div class="portfolio-details__info">
          <h3 class="portfolio-details__info__title">Produkti</h3>
          <ul style="padding-right: 0%" class="list-unstyled">
            <li class="row">
              <span class="col-4">Autori</span>

              <ng-container *ngIf="authors != undefined">


                <ng-container *ngIf="authors.length > 1 ">
                  <small class="row; col-8">
                    <ng-container *ngFor="let item of authors; let i = index; last as isLast">

                      <ng-container *ngIf="!isLast">
                        <a class="txt" (click)="searchSelected(item)">
                          {{item + ', '}}
                        </a>

                      </ng-container>

                      <ng-container *ngIf="isLast ">
                        <a class="txt" (click)="searchSelected(item)">
                          {{item}}
                        </a>
                      </ng-container>

                    </ng-container>


                  </small>
                </ng-container>

                <ng-container *ngIf="authors.length == 1 ">
                  <small class="row; col-8">
                    <a (click)="searchSelected(authors[0])"
                       class="txt">{{authors[0]}}</a>
                  </small>

                </ng-container>

              </ng-container>
            </li>
            <li class="row">
              <span class="col-4">Viti</span>
              <small class="row; col-8">
                <a class="txt" (click)="yearSelected(product.publicationYear)">
                  {{product.publicationYear}}
                </a>
              </small>
            </li>
            <li class="row">
              <span class="col-4">Tematika</span>

              <ng-container *ngIf="topics != undefined">


                <ng-container *ngIf="topics.length > 1 ">
                  <small class="row; col-8">
                    <ng-container *ngFor="let item of topics; let i = index; last as isLast">

                      <ng-container (click)="tematikaSelected(item.id)" *ngIf="!isLast">
                        <a class="txt" (click)="tematikaSelected(item.id)">
                          {{item.name + ', '}}
                        </a>

                      </ng-container>

                      <ng-container (click)="tematikaSelected(item.id)" *ngIf="isLast ">
                        <a class="txt" (click)="tematikaSelected(item.id)">
                          {{item.name}}
                        </a>
                      </ng-container>

                    </ng-container>


                  </small>
                </ng-container>

                <ng-container *ngIf="topics.length == 1 ">
                  <small class="row; col-8">
                    <a (click)="tematikaSelected(topics[0].id)"
                       class="txt">{{topics[0].name}}</a>
                  </small>

                </ng-container>

              </ng-container>


            </li>
            <li class="row">
              <span class="col-4">Mekanizmi</span>

              <ng-container *ngIf="mechanisms != undefined">
                <ng-container *ngIf="mechanisms.length > 1 ">
                  <small class="row; col-8">
                    <ng-container *ngFor="let item of mechanisms; let i = index; last as isLast">
                      <ng-container *ngIf="!isLast">
                        <a class="txt" (click)="mekanizmiSelected(item.id)">
                          {{item.name + ', '}}</a>
                      </ng-container>
                      <ng-container *ngIf="isLast ">
                        <a class="txt" (click)="mekanizmiSelected(item.id)">
                          {{item.name}}
                        </a>
                      </ng-container>
                    </ng-container>
                  </small>
                </ng-container>
                <ng-container *ngIf="mechanisms.length == 1 ">
                  <small class="row; col-8">
                    <a class="txt" (click)="mekanizmiSelected(mechanisms[0].id)"
                    >{{mechanisms[0].name}}</a>
                  </small>

                </ng-container>
              </ng-container>

            </li>
          </ul>
        </div>
        <div class="portfolio-details__info">
          <h3 class="portfolio-details__info__title">Projekti</h3>
          <ul style="padding-right: 0%" class="list-unstyled">
            <li class="row">
              <span class="col-4">Titulli</span>
              <small class="col-8">

                <a class="txt" (click)="searchSelected(product.project.title)">
                  {{product.project.title}}

                </a>
              </small>


            </li>
            <li class="row">
              <span class="col-4">Zbatues</span>
              <small class="col-8">{{product.project.implementor}}</small>
            </li>
            <li class="row">
              <span class="col-4">Zona e zbatimit</span>

              <ng-container *ngIf="locations != undefined">

                <ng-container *ngIf="locations.length > 1 ">
                  <small class="row; col-8">
                    <ng-container *ngFor="let item of locations; let i = index; last as isLast">

                      <ng-container *ngIf="!isLast">

                        <a class="txt" (click)="zonaSelected(item.municipalityId)">
                          {{item.municipality + ', '}}
                        </a>

                      </ng-container>

                      <ng-container *ngIf="isLast">
                        <a class="txt" (click)="zonaSelected(item.municipalityId)">
                          {{item.municipality}}
                        </a>
                      </ng-container>
                    </ng-container>
                  </small>
                </ng-container>

                <ng-container *ngIf="locations.length == 1 ">
                  <small class="row; col-8">

                    <a class="txt" (click)="zonaSelected(locations[0].municipalityId)">
                      {{locations[0].municipality}}
                    </a>
                  </small>


                </ng-container>

              </ng-container>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <br>
    <br>
  </div>
</section>
<app-footer-inner></app-footer-inner>
