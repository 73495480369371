import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {Product} from '../interfaces/project.interface';

@Injectable()
export class PublicProductsService {

  public uri = environment.baseUrl;


  constructor(private http: HttpClient) {
  }

  public getOne(productId: number): Observable<HttpResponse<Product>> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const url = `${this.uri}/api/public/products/${productId}`;

    return this.http.get<Product>(url, {observe: 'response', headers});
  }


}
