<div class="logo-box">
    <a aria-label="logo image"><img src="assets/images/resources/logo-1.png" width="155" alt="img" /></a>
</div>
<div class="mobile-nav__container">
    <ul class="main-menu__list">
        <li style="cursor:pointer;" *ngFor="let item of navigation">
            <a *ngIf="!isActiveRoute(item.link)" (click)="navigate(item.link)" >{{item.linkText}}</a>
            <a *ngIf="isActiveRoute(item.link)" (click)="isActiveProductPage()" >{{item.linkText}}</a>
        </li>
      <li  style="cursor:pointer;" *ngIf="isAuthenticated">
        <a (click)="logOut()">Logout</a>
      </li>
      <li style="cursor:pointer;" *ngIf="!isAuthenticated">
        <a *ngIf="!isActiveRoute('/login')" (click)="navigate('/login')">Login</a>
        <a *ngIf="isActiveRoute('/login')">Login</a>
      </li>
      <li style="cursor:pointer;" *ngIf="isAuthenticated">
<!--        <a *ngIf="!isActiveRoute('/project')" (click)="navigate('/project')">Shto Projekt</a>-->
<!--        <a *ngIf="isActiveRoute('/project')">Projektet</a>-->
      </li>
      <li style="cursor:pointer;" *ngIf="isAuthenticated">
        <a *ngIf="!isActiveRoute('/create-product')" (click)="navigate('/create-product')">Shto Produkt</a>
        <a *ngIf="isActiveRoute('/create-product')">Shto Produkt</a>
      </li>
    </ul>
</div>

