<header class="main-header main-header--one  clearfix">
  <div class="main-header-one__bottom">
    <div class="auto-container">
      <div class="main-header-one__bottom-inner clearfix">
        <nav class="main-menu main-menu--1">
          <div style="padding-right: 30%" class="main-menu__inner">
            <div class="left">
              <div class="logo-box1">
                <div class="logo-box1_bg">
                </div>
                <a routerLink="/">
                  <img src="assets/images/resources/logo-1.png" alt="img">
                </a>
              </div>
            </div>
            <a href="javascript:void(0)" class="mobile-nav__toggler" (click)="toggleNav()"><i
              class="fa fa-bars"></i></a>
            <div class="middle">
              <ul class="main-menu__list">
                <li [ngClass]="item.child === true ? 'dropdown' : ''" *ngFor="let item of navigation">
                  <a href="javascript:void(0)" *ngIf="item.child">{{item.linkText}}<span
                    class="nav_arrow"></span></a>
                  <a  (click)="isActiveProductPage()" routerLink="{{item.link}}" *ngIf="!item.child">{{item.linkText}}</a>
                  <ul class="sub-menu" *ngIf="item.child">
                    <li [ngClass]="item.child === true ? 'dropdown' : ''"
                        *ngFor="let item of item.submenu">
                      <a href="javascript:void(0)" *ngIf="item.child">{{item.linkText}}<span
                        class="nav_arrow"></span></a>
                      <a (click)="isActiveProductPage()" routerLink="{{item.link}}" *ngIf="!item.child">{{item.linkText}}</a>
                      <ul class="sub-menu" *ngIf="item.child">
                        <li *ngFor="let item of item.submenu">
                          <a (click)="isActiveProductPage()" routerLink="{{item.link}}">{{item.linkText}}</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li  style="cursor:pointer;" *ngIf="isAuthenticated">
                  <a (click)="logOut()">Logout</a>
                </li>
                <li *ngIf="!isAuthenticated">
                  <a routerLink="/login">Login</a>
                </li>
<!--                <li *ngIf="isAuthenticated">-->
<!--                  <a routerLink="/project">Shto Projekt</a>-->
<!--                </li>-->
                <li *ngIf="isAuthenticated">
                  <a routerLink="/create-product">Shto Produkt</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</header>
<div style="padding-right: 30%" class="stricky-header stricked-menu main-menu"
     [ngClass]="windowScrolled ? 'stricky-fixed':''">
  <div class="sticky-header__content">
    <!-- menu -->
    <div class="main-menu__inner">
      <div class="left">
        <div class="logo-box1">
          <div class="logo-box1_bg">
          </div>
          <a routerLink="/">
            <img src="assets/images/resources/logo-1.png" alt="img">
          </a>
        </div>
      </div>
      <a href="javascript:void(0)" class="mobile-nav__toggler" (click)="toggleNav()"><i class="fa fa-bars"></i></a>
      <div class="middle">
        <ul class="main-menu__list">
          <li [ngClass]="item.child === true ? 'dropdown' : ''" *ngFor="let item of navigation">
            <a href="javascript:void(0)" *ngIf="item.child">{{item.linkText}}<span
              class="nav_arrow"></span></a>
            <a (click)="isActiveProductPage()" routerLink="{{item.link}}" *ngIf="!item.child">{{item.linkText}}</a>
            <ul class="sub-menu" *ngIf="item.child">
              <li [ngClass]="item.child === true ? 'dropdown' : ''"
                  *ngFor="let item of item.submenu">
                <a href="javascript:void(0)" *ngIf="item.child">{{item.linkText}}<span class="nav_arrow"></span></a>
                <a (click)="isActiveProductPage()" routerLink="{{item.link}}" *ngIf="!item.child">{{item.linkText}}</a>
                <ul class="sub-menu" *ngIf="item.child">
                  <li *ngFor="let item of item.submenu">
                    <a (click)="isActiveProductPage()" routerLink="{{item.link}}">{{item.linkText}}</a>
                  </li>
                </ul>
              </li>

            </ul>
          </li>
          <li style="cursor:pointer;" *ngIf="isAuthenticated">
            <a (click)="logOut()">Logout</a>
          </li>
          <li *ngIf="!isAuthenticated">
            <a routerLink="/login">Login</a>
          </li>
<!--          <li *ngIf="isAuthenticated">-->
<!--            <a routerLink="/project">Shto Projekt</a>-->
<!--          </li>-->
          <li *ngIf="isAuthenticated">
            <a routerLink="/create-product">Shto Produkt</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="mobile-nav__wrapper" [ngClass]="navmethod ? 'expanded' : ''">
  <div class="mobile-nav__overlay mobile-nav__toggler" (click)="toggleNav()"></div>
  <div class="mobile-nav__content">
    <span class="mobile-nav__close mobile-nav__toggler" (click)="toggleNav()"><i class="fa fa-times"></i></span>
    <app-mobile-menu (routeChangeEvent)="resetProducts()"></app-mobile-menu>
  </div>
</div>
